<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="card-header" style="border-bottom: none">
                    Transactions
                </div>
                <div class="card-body pt-0">
                    <ul class="nav nav-tabs style1" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" :class="[context!=='sent'&&context!=='swap'?'active': '']" data-bs-toggle="tab" href="#overview2" role="tab">
                                <ion-icon name="arrow-down-outline"></ion-icon>
                                Received
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="[context==='sent'?'active': '']" id="sentTab" data-bs-toggle="tab" href="#cards2" role="tab">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                                Sent
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="[context==='swap'?'active': '']" id="swapTab" data-bs-toggle="tab" href="#cards3" role="tab">
                                <ion-icon name="swap-vertical-outline"></ion-icon>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content mt-2">
                        <div class="tab-pane fade" :class="[context!=='sent'&&context!=='swap'?'show active': '']" id="overview2" role="tabpanel">
                            <template v-if="getReceivedPayments.length <= 0">
                                <div class="col-12 text-center">
                                    No received history
                                </div>
                            </template>
                            <template v-else>
                                <!-- timeline -->
                                <div class="timeline timed ms-1 me-2">
                                    <div class="item" v-for="(payment, idx) in getReceivedPayments" :key="idx">
                                        <span class="time">
                                          {{getReadableDatetime(payment.data.created_at)}}
                                          <template v-if="payment.data.confirmations">
                                            <small class="float-end text-dark">
                                              <ion-icon name="contract-outline"></ion-icon>blocks ({{payment.data.confirmations.no_of_confirmations}}/{{payment.data.confirmations.required_confirmations}})
                                            </small>
                                          </template>
                                        </span>
                                        <div class="dot" :class="[
                                            payment.data.api_response_status==='PENDING'?'bg-warning':'',
                                            payment.data.api_response_status==='FAILED'?'bg-danger':'',
                                            payment.data.api_response_status==='CONFIRMED'?'bg-success':'',
                                        ]"></div>
                                        <div class="content">
                                            <h4 class="title text-lowercase">
                                              {{payment.data.api_response_status}}
                                            </h4>
                                            <div class="text" v-if="payment.data.api_response_status==='CONFIRMED'">
                                                <template v-if="payment.data.paid_through==='MERCHANT'&&payment.data.api_webhook.confirmed.event.data.payments.length>0">
                                                  Deposit of {{payment.data.api_webhook.confirmed.event.data.payments[0].net.crypto.amount}} {{payment.data.crypto}}
                                                  to {{payment.data.address}}
                                                </template>
                                              <template v-else>
                                                Deposit of {{payment.data.crypto_value}} {{payment.data.crypto}}
                                                to {{payment.data.address}}
                                              </template>
                                            </div>
                                          <div class="text" v-else>
                                            Deposit of {{payment.data.crypto_value}} {{payment.data.crypto}}
                                            to {{payment.data.address}}
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- * timeline -->
                            </template>
                        </div>
                        <div class="tab-pane fade" :class="[context==='sent'?'show active': '']" id="cards2" role="tabpanel">
                            <template v-if="getSentPayments.length <= 0">
                                <div class="col-12 text-center">
                                    No sent history
                                </div>
                            </template>
                            <template v-else>
                                <!-- timeline -->
                                <div class="timeline timed ms-1 me-2">
                                    <div class="item" v-for="(payment, idx) in getSentPayments" :key="idx">
                                        <span class="time">
                                          {{getReadableDatetime(payment.data.created_at)}}
                                          <template v-if="payment.data.confirmations">
                                            <small class="float-end text-dark">
                                              <ion-icon name="contract-outline"></ion-icon>blocks ({{payment.data.confirmations.no_of_confirmations}}/{{payment.data.confirmations.required_confirmations}})
                                            </small>
                                          </template>
                                        </span>
                                        <div class="dot bg-primary" :class="[
                                            payment.data.status==='PENDING'?'bg-warning':'',
                                            payment.data.status==='DECLINED'?'bg-danger':'',
                                            payment.data.status==='CONFIRMED'?'bg-success':'',
                                        ]"></div>
                                        <div class="content">
                                            <h4 class="title text-lowercase">
                                                {{payment.data.status}}
                                            </h4>
                                            <div class="text">
                                                payment of {{payment.data.crypto_value}} {{payment.data.crypto}}
                                                <!--                                                Payment of {{getFiatSymbol(payment.data.currency)}}{{payment.data.currency_value}}-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- * timeline -->
                            </template>
                        </div>
                        <div class="tab-pane fade" :class="[context==='swap'?'show active': '']" id="cards3" role="tabpanel">
                            <template v-if="getSwapPayments.length <= 0">
                                <div class="col-12 text-center">
                                    No conversion history
                                </div>
                            </template>
                            <template v-else>
                                <!-- timeline -->
                                <div class="timeline timed ms-1 me-2">
                                    <div class="item" v-for="(payment, idx) in getSwapPayments" :key="idx">
                                        <span class="time">{{getReadableDatetime(payment.data.created_at)}}</span>
                                        <div class="dot bg-success"></div>
                                        <div class="content">
                                            <h4 class="title text-lowercase">
                                                {{payment.data.context}}
                                            </h4>
                                            <div class="text text-success">
                                                {{payment.data.description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- * timeline -->
                            </template>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import methodsMixin from "../utils/methodsMixin";

export default {
    name: "Transactions",
    data(){
        return {
            timeline_color_class: [
                '', 'bg-primary', 'bg-info',
                'bg-danger', 'bg-warning'
            ],
            context: this.$route.query.context
        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('transaction', [
            'getReceivedPayments',
            'getSentPayments',
            'getSwapPayments'
        ])
    },
    mounted() {
        this.$store.dispatch('transaction/fetchReceivedPayments')
            .then(()=>{
                return this.$store.dispatch('transaction/fetchSentPayments')
                    .then(()=>{
                        return this.$store.dispatch('transaction/fetchSwapPayments')
                    })
            })
        // const sentTab = $('#sentTab');
        // sentTab.on('shown.bs.tab', ()=>{
        //     this.$store.dispatch('transaction/fetchSentPayments')
        // })
    }
}
</script>

<style scoped>

</style>